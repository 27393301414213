<template>
  <div class="row">
    <div :class="'col-12 ' + all != 'yes' ? 'col-lg-5 c' : 'col-lg-3'">
      <div class="card card-body">
        <h1 class="text-info">{{ stats.users }}</h1>
        <small><i class="fa fa-users"></i> عدد المستخدمين </small>
      </div>
    </div>
    <div :class="'col-12 ' + all != 'yes' ? 'col-lg-5 c' : 'col-lg-3'">
      <div class="card card-body">
        <h1 class="text-primary">{{ stats.devices }}</h1>
        <small><i class="fa fa-rss"></i> عدد الاجهزة المتصلة </small>
      </div>
    </div>
    <div :class="'col-12 ' + all != 'yes' ? 'col-lg-5 c' : 'col-lg-3'">
      <div class="card card-body">
        <h1 class="text-warning">{{ stats.messages }}</h1>
        <small><i class="fa fa-paper-plane"></i> اجمالي الرسائل المرسلة </small>
      </div>
    </div>
    <div :class="'col-12 ' + all != 'yes' ? 'col-lg-5 c' : 'col-lg-3'">
      <div class="card card-body">
        <h1 class="text-secondary">{{ stats.messages_today }}</h1>
        <small
          ><i class="fa fa-paper-plane"></i> اجمالي الرسائل المرسلة اليوم
        </small>
      </div>
    </div>
    <div :class="'col-12 ' + all != 'yes' ? 'col-lg-5 c' : 'col-lg-3'">
      <div class="card card-body">
        <h1 class="text-secondary">{{ stats.all_devices }}</h1>
        <small
          ><i class="fa fa-mobile"></i> اجمالي الاجهزة المضافة
        </small>
      </div>
    </div>
    <div :class="'col-12 ' + all != 'yes' ? 'col-lg-5 c' : 'col-lg-3'">
      <div class="card card-body">
        <h1 class="text-secondary">{{ stats.all_active_users }}</h1>
        <small
          ><i class="fa fa-users"></i> المستخدمين الفعالين
        </small>
      </div>
    </div>
  </div>
</template>

<script>
import { $themeColors } from "@themeConfig";
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    VueApexCharts,
  },
  data() {
    var goBackDays = 28;

    var today = new Date();
    var daysSorted = [today.toISOString().split("T")[0]];

    for (var i = 0; i < goBackDays; i++) {
      var newDate = new Date(today.setDate(today.getDate() - 1));
      daysSorted.push(newDate.toISOString().split("T")[0]);
    }
    return {
      all: window.location.href.includes("all") ? "yes" : false,
      //
      stats: {},
      user: JSON.parse(localStorage.getItem("user")),
      // chart
      series: [
        {
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        markers: {
          strokeWidth: 7,
          strokeOpacity: 1,
          strokeColors: [$themeColors.light],
          colors: [$themeColors.success],
        },
        colors: [$themeColors.success],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        tooltip: {
          custom(data) {
            return `${'<div class="px-1 py-50"><span> ✈️'}${
              data.series[data.seriesIndex][data.dataPointIndex]
            } رسالة</span></div>`;
          },
        },
        xaxis: {
          categories: daysSorted,
        },
        chartOptions: {
          // opposite: isRtl,
        },
      },
    };
  },
  created() {
    var g = this;
    $.post(api + "/admin/general/stats", {
      jwt: this.user.jwt,
      all: this.all,
    })
      .then(function (r) {
        g.stats = r.response;
        g.series = [
          {
            data: g.stats.series,
          },
        ];
      })
      .catch(function () {
        alert("فشل الاتصال بالسيرفر");
      });
  },
};
</script>

<style>
</style>